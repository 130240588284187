import { version } from '../../package.json';
const location: any = {
  ddesmobilize: {
    uri: 'https://api.ddesmobilize.com.br/ADV/',
  },
  viacep: {
    url: (cep: string) => `https://viacep.com.br/ws/${cep}/json/`,
  },
  viacnpj: {
    url: (cnpj: string) => `https://publica.cnpj.ws/cnpj/${cnpj}`,
  }
}
export const endpoint = () => location;

const relative: any = {
  authenticate: 'ApiLogin.rule',
  like: 'ApiFavotirar.rule',
  passwordReset: 'ApiResetarSenha.rule',
  register: 'ApiCadastro.rule',
  bid: 'ApiDarLance.rule',
  paymentMethod: (id: string) => `ApiPagueAgora.rule&IdAnuncio=${id}`,
  validade: (payload: string) => `ApiValidarCpfCnpj.rule&CnpjCpf=${payload}`,
  favorites: 'ApiFavoritos.rule',
  filter: 'ApiFiltro.rule',
  showroom: 'ApiAnuncio.rule',
  showcase: 'ApiAnuncioPf.rule',
  readme: 'ApiTermosUso.rule',
  disclaimer: 'ApiTermosCompra.rule',
  slide: 'ApiImagemPrincipal.rule',
  purchase: 'ApiPropostas.rule',
  dut: 'ApiCadastroDut.rule',
  updateUser: 'ApiAlterarDadosPessoais.rule',
  offer: 'ApiFazerProposta.rule'
}
export const path = () => relative;

export const environment: any = {
  production: true,
  namespace: 'production',
  version,
  authenticate: endpoint().ddesmobilize.uri + path().authenticate,
  like: endpoint().ddesmobilize.uri + path().like,
  passwordReset: endpoint().ddesmobilize.uri + path().passwordReset,
  register: endpoint().ddesmobilize.uri + path().register,
  paymentMethod: (id:string) => endpoint().ddesmobilize.uri + path().paymentMethod(id),
  validade: (payload:string) => endpoint().ddesmobilize.uri + path().validade(payload),
  bid: endpoint().ddesmobilize.uri + path().bid,
  favorites: endpoint().ddesmobilize.uri + path().favorites,
  filter: endpoint().ddesmobilize.uri + path().filter,
  showroom: endpoint().ddesmobilize.uri + path().showroom,
  showcase: endpoint().ddesmobilize.uri + path().showcase,
  readme: endpoint().ddesmobilize.uri + path().readme,
  disclaimer: endpoint().ddesmobilize.uri + path().disclaimer,
  slide: endpoint().ddesmobilize.uri + path().slide,
  purchase: endpoint().ddesmobilize.uri + path().purchase,
  viacep: endpoint().viacep,
  viacnpj: endpoint().viacnpj,
  dut: path().dut,
  updateUser: endpoint().ddesmobilize.uri + path().updateUser,
  offer: endpoint().ddesmobilize.uri + path().offer,
}
export const env = () => environment;
